import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { _templateDelete, _templatesGet } from '../../services/apiConnections.service';

export const SmsTemplates = () => {

    const [templates, setTemplates] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (token && !templates.length && !emptyTable) {
            getData(token);
        }
    }, [token, templates, emptyTable]);

    const getData = async (token, search='') => {
        let result = await _templatesGet(token, search);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setEmptyTable(!result.sms_templates.length);
            setTemplates(result.sms_templates);
        }
        setError(result.error);
    };

    const handleFilter = (value) => {
        getData(token, value);
    };

    const editFormatter = (cell, row) => {
        return (
            <Link className='tableIconBtn' to={`/sms-templates/${cell}`}>
                <FontAwesomeIcon icon={solid('pencil')} />
            </Link>
        )
    };

    const deleteFormatter = (cell, row) => {
        return (
            <button
                className='tableIconBtn trashBtn'
                onClick={async () => {
                    await _templateDelete(token, row.ID);
                    getData(token);
                }}>
                <FontAwesomeIcon icon={regular('trash-can')} />
            </button>
        )
    };

    const columns = [{
        dataField: 'name',
        text: 'Template',
        sort: true
    },
    {
        dataField: 'ID',
        text: 'Edit',
        classes: 'iconColumn',
        formatter: editFormatter
    },
    {
        dataField: 'user_id',
        text: 'Delete',
        classes: 'iconColumn',
        formatter: deleteFormatter
    }];

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'asc'
        }
    ];

    const noData = () => {
        return <p className='emptyTableMessage'>You have no templates yet! Create it</p>
    };

    return (
        <div className='content wideContent'>
            <div>
                <div className='pageTitle'>
                    <span>SMS Templates</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader tableHeaderInline'>
                                <div className='filterCol'>
                                    <label
                                        className='inputLabel'
                                        htmlFor='templateFilter'>Template</label>
                                    <input
                                        name='templateFilter'
                                        className='searchInput'
                                        placeholder='Search...'
                                        onChange={(event) => handleFilter(event.target.value)} />
                                </div>
                                <Link className='mainBtn btn' to={'/create-template'}>Create Template</Link>
                            </div>
                            <BootstrapTable
                                keyField='ID'
                                data={templates}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}