import React from 'react';

export const Support = () => {
    return (
        <div className='content supportPage'>
            <div className='pageTitle'>
                <span>Support</span>
            </div>
            <div className='row supportPageContent'>
                <p>If you experiencing any operational difficulties please contact support team now:</p>

                <p><b>Email:</b> support@speedflow.com</p>
                <p className='br'><b>Skype:</b> speedflow.sts</p>

                <p className='br'>Our support team will be happy to help you 24/7</p>

                <p>Yours sincerely,</p>
                <p>NexaMSG</p>
            </div>
        </div>
    );
}