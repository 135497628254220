import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast, Toaster } from 'react-hot-toast';
import { _balanceTopUp, _ordersGet, _tariffPlansGet } from '../../services/apiConnections.service';

import { ModalDialog } from '../elements/ModalDialog.component';
import { TariffPlanTile } from '../elements/TariffPlanTile.component';

export const Dashboard = () => {

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [showModalTopUp, setShowModalTopUp] = useState(false);
    const [showModalBuyTariff, setShowModalBuyTariff] = useState(false);
    const [showModalTariffPlans, setShowModalTariffPlans] = useState(false);
    const [balance, setBalance] = useState(null);
    const [tariffs, setTariffs] = useState([]);
    const [noTariffs, setNoTariffs] = useState(false);
    const [orders, setOrders] = useState([]);
    const [noOrders, setNoOrders] = useState(false);

    const [topUpForm, setTopUpForm] = useState({
        topUpBy: 10
    });
    const topUpByValues = [10, 20, 30];

    let user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        setBalance(user.balance);
        if (token && !tariffs.length && !noTariffs)
            getTariffPlans();
        if (token && !orders.length && !noOrders)
            getOrders();
    }, [token, user, tariffs, noTariffs, orders, noOrders]);

    const getTariffPlans = async () => {
        let result = await _tariffPlansGet(token);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setNoTariffs(!result.tariff_packages.length);
            setTariffs(result.tariff_packages);
        }
        setError(result.error);
    };

    const getOrders = async () => {
        let result = await _ordersGet(token);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setNoOrders(!result.orders.length);
            setOrders(result.orders);
        }
        setError(result.error);
    };

    const handleTopUpRadioChange = (e) => {
        setTopUpForm({
            ...topUpForm,
            [e.target.name]: Number(e.target.value)
        });
    };

    const handleInfoUpdate = (tariffName) => {
        setShowModalBuyTariff(false);
        toast.success(`Congratulations, the tariff ${tariffName} has been purchased`);
        getOrders();
    };

    const handleTopUp = async (amount) => {
        let result = await _balanceTopUp(token, amount);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            window.location.href = result.url;
        }
        setError(result.error);
        setShowModalTopUp(false);
    };

    return (
        <div className='content dashboard'>
            <div className='pageTitle'>
                <span>Dashboard</span>
            </div>
            {error && <p className='msgBlock errorMsg'>{error}</p>}
            <div className='dashboardBlock'>
                <div className='dashboardTop'>
                    <div className='dashboardTopBlock'>
                        <button
                            className='tariffPlansBtn'
                            onClick={() => {
                                if (noOrders) return;
                                setShowModalTariffPlans(true);
                            }}>Tariff Plan: <span className='marginR10'>{orders.length}</span></button>
                        <button
                            className='mainBtn btn'
                            onClick={() => setShowModalBuyTariff(true)}>Buy</button>
                    </div>
                    <div className='dashboardTopBlock'>
                        <span className=''>Account Balance: <span className='balance'>{balance}</span></span>
                        <button
                            className='mainBtn btn'
                            onClick={() => setShowModalTopUp(true)}>Top Up</button>
                    </div>
                </div>
                <div className='dashboardMain'>
                    <div className='dashboardLeft'>
                        <ul className='statistic'>
                            <li>
                                <span className='statsTitle'>In the Queue</span>
                                <span className='statsValue'>12</span>
                            </li>
                            <li>
                                <span className='statsTitle'>Sent</span>
                                <span className='statsValue'>20</span>
                            </li>
                            <li>
                                <span className='statsTitle'>Delivered</span>
                                <span className='statsValue'>7</span>
                            </li>
                        </ul>
                    </div>
                    <div className='dasboardRight'>
                        <div className='diagram'>
                            <div className='diagramTitle'><span></span><span>Traffic per hour</span></div>
                            <div className='diagramBody'>
                                <div className='vertAxis'>
                                    <ul>
                                        <li>1</li>
                                        <li>2</li>
                                        <li>3</li>
                                        <li>4</li>
                                        <li>5</li>
                                    </ul>
                                </div>
                                <div className='horAxis'>
                                    <div className='graph'></div>
                                    <ul>
                                        <li>00:00</li>
                                        <li>03:00</li>
                                        <li>06:00</li>
                                        <li>09:00</li>
                                        <li>12:00</li>
                                        <li>15:00</li>
                                        <li>18:00</li>
                                        <li>21:00</li>
                                        <li>23:00</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDialog
                show={showModalTopUp}
                onHide={() => setShowModalTopUp(false)}
                onAccept={() => handleTopUp(+topUpForm.topUpBy)}
                title="Balance Top up"
                acceptBtnText="Top Up">
                <Form className='centeredContent'>
                    <span className='subtitle'>Top up by:</span>
                    {topUpByValues.map((value) => (
                        <Form.Check
                            type='radio'
                            name='topUpBy'
                            id={`topUpBy${value}`}
                            key={`topUpBy${value}`}
                            value={value}
                            label={`${value}$`}
                            checked={topUpForm.topUpBy === value}
                            onChange={handleTopUpRadioChange} />
                    ))}
                </Form>
            </ModalDialog>
            <Modal
                className='tariffPlansModal'
                show={showModalTariffPlans}
                onHide={() => setShowModalTariffPlans(false)}
                backdrop={true}
                keyboard={false}
                centered>
                {orders.length > 0 &&
                    orders.map((tariff) => {
                        return (
                            <TariffPlanTile
                                key={tariff.ID}
                                tariff={tariff}
                                bought />
                        )
                    })
                }
            </Modal>
            <Modal
                className='tariffPlansModal'
                show={showModalBuyTariff}
                onHide={() => setShowModalBuyTariff(false)}
                backdrop={true}
                keyboard={false}
                centered>
                {noTariffs &&
                    <div className='emptyDialog'>No tariff plans are available</div>
                }
                {tariffs.length > 0 &&
                    tariffs.map((tariff) => {
                        return (
                            <TariffPlanTile
                                key={tariff.ID}
                                tariff={tariff}
                                token={token}
                                onBuy={handleInfoUpdate} />
                        )
                    })
                }
            </Modal>
            <Toaster />
        </div>
    );
}