import React, { useState } from 'react';

import { _resetPassword } from '../../services/apiConnections.service';

export const ForgotYourPassword = () => {

    const [email, setEmail] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [isLinkSended, setLinkSended] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await _resetPassword(email);
        setLinkSended(true);
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'email':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        email: "Email is required"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['email'];
                    setFormErrors(copy);
                }
                break;
            default: break;
        }
    };

    const formIsValid = () => {
        return email;
    };

    return (
        <div className='forgotPasswordFormWrapper'>
            {isLinkSended ?
                <div className='forgotPasswordMessageWindow'>
                    <p>Please check your inbox or spam folder - if this email is registered, you will receive password reset link.</p>
                </div>
                :
                <form onSubmit={handleSubmit}>
                    <div className='loginPageLogo'></div>
                    <div className='mb-3'>
                        <label
                            className='loginLabel'
                            htmlFor='email'>Email</label>
                        <div className='inputWrapper'>
                            <input
                                id='email'
                                name='email'
                                type="email"
                                className={`loginInput ${formErrors.email ? 'invalidInput' : ''}`}
                                placeholder="Email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.email}</span>
                        </div>
                    </div>
                    <div className="btnWrapper">
                        <button
                            type="submit"
                            className="btn loginBtn mainBtn"
                            disabled={!formIsValid()}>Send password link</button>
                    </div>
                </form>
            }
        </div>
    );
};