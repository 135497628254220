import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast, Toaster } from 'react-hot-toast';

import { _contactsGet, _sendersGet, _sendSMS } from '../../services/apiConnections.service';
import { FormData } from '../../helpers/FormData';

import { SmsCalculator } from '../elements/smsCalculator.component';

export const SendSms = () => {

    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    const [token, setToken] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        sender: '',
        receiver: '',
        message_text: '',
        changed_message: ''
    });
    const [senders, setSenders] = useState([]);
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (token) {
            setToken(token);
            handleSenders();
            handleContacts();
        }
    }, [token]);

    useEffect(() => {
        setWarning('');
        handleFormValueChange('changed_message', '');
        let vars = extractTemplateVars(formValues.message_text);
        let varsPresent = handleTemplateVars(vars);
        if (vars.length) {
            if (!formValues.receiver) {
                setWarning('Select a contact for the correct calculation of the number of SMS');
            } else {
                let receiver = formValues.receiver;
                let missingVars = [];
                for (let variable of vars) {
                    if (receiver.fields.length) {
                        for (let field of receiver.fields) {
                            if (field.key === variable) {
                                let str = applyVars(formValues.message_text, { [field.key]: field.value })
                                handleFormValueChange('changed_message', str);
                                varsPresent[variable] = true;
                                break;
                            } else {
                                varsPresent[variable] = false;
                            }
                        }
                    } else {
                        varsPresent[variable] = false;
                    }
                }
                for (const key in varsPresent) {
                    if (!varsPresent[key]) missingVars.push(key);
                }
                if (missingVars.length) setWarning(`The variable(s) "${missingVars.toString().split(',').join('", "')}" is(are) missing, so message will be sent as is`);
            }
        }
    }, [formValues.receiver, formValues.message_text]);

    const handleTemplateVars = (vars) => {
        let varsObj = vars.reduce((acc, cur) => {
            acc[cur] = null;
            return acc;
        }, {});
        return varsObj;
    };

    const handleSenders = async () => {
        let result = await _sendersGet(token);
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.senders.map((e) => {
                return {
                    label: e.number,
                    value: e.id
                }
            });
            setSenders(arr);
        }
    };

    const handleContacts = async () => {
        let result = await _contactsGet(token);
        let arr = result.Contacts.map((e) => {
            return {
                label: e.name,
                value: e.ID,
                number: e.number,
                fields: e.fields
            }
        });
        setContacts(arr);
    };

    const extractTemplateVars = (template) => {
        const regex = /\{\{([0-9a-zA-Z_]*?)\}\}/g;

        let match, matches = [];
        while ((match = regex.exec(template)) !== null) {
            matches.push(match[1]);
        }
        return matches;
    };

    const applyVars = (string, args) => {
        const regex = /\{\{([0-9a-zA-Z_]+?)\}\}/g;

        if (!args || !args.hasOwnProperty) {
            args = {};
        }

        return string.replace(regex, function replaceArg(match, i, index) {
            var result;

            if (string[index - 1] === "{" && string[index + match.length] === "}") {
                return i;
            } else {
                result = i in args ? args[i] : null;
                if (result === null || result === undefined) {
                    return string.substr(index, match.length);
                }
                return result;
            }
        });
    };

    const handleMessageSend = async (event) => {
        event.preventDefault();
        let result = await _sendSMS(token, formValues);
        if (result.error) {
            setError(result.error);
        } else {
            toast.success(`SMS has been sent`);
        }
    };

    const formIsValid = () => {
        return formValues.sender && formValues.receiver && (formValues.message_text.length || formValues.changed_message.length);
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>Send SMS</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='pageContent'>
                    <div className='mb-3'>
                        <label
                            className='inputLabel'
                            htmlFor='sender'>Sender</label>
                        <Select
                            id='sender'
                            name='sender'
                            className='customSelect'
                            classNamePrefix='select'
                            options={senders}
                            placeholder='Select sender'
                            value={formValues.sender}
                            onChange={(selectedOption) => handleFormValueChange(
                                'sender',
                                selectedOption
                            )} />
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel'
                            htmlFor='contacts'>Recipient</label>
                        <Select
                            id='contacts'
                            name='contacts'
                            className='customSelect'
                            classNamePrefix='select'
                            options={contacts}
                            value={formValues.receiver}
                            placeholder='Select recipient'
                            onChange={(selectedOption) => handleFormValueChange(
                                'receiver',
                                selectedOption
                            )} />
                    </div>
                    <SmsCalculator
                        defaultEncoder='7bit'
                        value={formValues.message_text}
                        warning={warning}
                        changedMessage={formValues.changed_message}
                        onChange={(event) => handleFormValueChange(
                            'message_text',
                            event.target.value
                        )} />
                    <div className='buttonBlock'>
                        <button
                            className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                            onClick={handleMessageSend}
                            disabled={!formIsValid()}>Send</button>
                    </div>
                </div>
            </div>
            <Toaster />
        </div>
    );
}