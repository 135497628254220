import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import { toast, Toaster } from 'react-hot-toast';

import { FormData } from '../../helpers/FormData';
import { _campaignGetById, _campaignUpsert, _getTimeZones, _sendersGet, _templatesGet } from '../../services/apiConnections.service';
import { SmsCalculator } from '../elements/smsCalculator.component';
import { TariffPlanTile } from '../elements/TariffPlanTile.component';

export const CampaignEdit = (props) => {

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [campaignValues, handleCampaignValueChange, setCampaignValues] = FormData({
        campaign_name: '',
        sender: {},
        template: {},
        message_text: '',
        receivers: [],
        time_zone: {
            label: 'default',
            value: 99999999
        },
        start_date: ''
    });
    const [prevCampaignValues, setPrevCampaignValues] = useState({});
    const [campaignInfo, setCampaignInfo] = useState({
        id: '',
        price: '',
        orders_used: 0,
        messages_count: 0,
        suggestions: [],
        tariffs: []
    });
    const [senders, setSenders] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [templateVars, setTemplateVars] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [startDate, setStartDate] = useState(
        new Date(
            new Date(
                new Date().setHours(
                    new Date().getHours() + 1
                )
            ).setMinutes(0)
        )
    );
    const [prevStartDate, setPrevStartDate] = useState(startDate);
    const [minTime, setMinTime] = useState(new Date());
    const [timeZones, setTimeZones] = useState([]);

    const [schedule, setSchedule] = useState({
        label: 'Send now',
        value: 0
    });

    const [showModalBuyTariff, setShowModalBuyTariff] = useState(false);
    const [showSubStep, setShowSubStep] = useState(false);

    const [havePackage, setHavePackage] = useState(false);
    const [availablePackage, setAvailablePackage] = useState(false);
    const [overLimit, setOverLimit] = useState(false);

    let params = useParams();

    let schedules = [
        {
            label: 'Send now',
            value: 0
        },
        {
            label: 'Send later',
            value: 1
        }
    ];

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (token) {
            handleSenders();
            handleTemplates();
            handleTimeZones();
        }
    }, [token]);

    useEffect(() => {
        if (senders.length && templates.length) {
            handleCampaignGet(token);
        }
    }, [senders, templates, timeZones]);

    const handleSenders = async () => {
        let tmpSenders = await _sendersGet(token);
        let arr = tmpSenders.senders.map((e) => {
            return {
                label: e.number,
                value: e.id
            }
        });
        setSenders(arr);
    };

    const handleTemplates = async () => {
        let result = await _templatesGet(token);
        let arr = result.sms_templates.map((e) => {
            return {
                label: e.name,
                value: e.ID,
                content: e.content
            }
        });
        setTemplates(arr);
    };

    const handleTimeZones = async () => {
        let result = await _getTimeZones(token);
        let arr = result.time_zones.map((e) => {
            return {
                label: e.name,
                value: result.time_zones.indexOf(e)
            }
        });
        setTimeZones(arr);
    };

    const handleReceivers = (receivers) => {
        let arr = receivers.map((e) => {
            return {
                label: e.name,
                value: e.ID,
                fields: e.fields,
                number: e.number
            }
        });
        return arr;
    };

    const handleCampaignGet = async (token) => {
        let result = await _campaignGetById(token, params.id);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setPrevCampaignValues({
                'campaign_name': result.name,
                'sender': senders.find((e) => e.label == result.sender),
                'template': templates.find((e) => e.value == result.template_id) || { label: 'No template', value: 0 },
                'message_text': result.text,
                'receivers': handleReceivers(result.recipients),
                'start_date': result.start_at,
                'time_zone': timeZones.find(e => e.label == (result.time_zone ? result.time_zone : Intl.DateTimeFormat().resolvedOptions().timeZone))
            })
            setStartDate(new Date(result.start_at));
        }
        setError(result.error);
    };

    useEffect(() => {
        setCampaignValues(prevCampaignValues);
    }, [prevCampaignValues]);

    const handleUpsert = async (event, draft = false, cancel = false) => {
        event.preventDefault();

        if (draft) {
            let result = await _campaignUpsert(token, campaignValues, params.id, true);
            if (result.error) {
                console.log("error");
                setError(result.error);
            } else {
                let getResult = await _campaignGetById(token, result.ID);
                if (getResult.error) {
                    setError(getResult.error);
                } else {
                    setCampaignInfo({
                        id: getResult.ID,
                        price: getResult.price,
                        orders_used: getResult.orders_used,
                        messages_count: getResult.messages_count,
                        suggestions: getResult.suggestions,
                        tariffs: getResult.orders_usage
                    });
                    setHavePackage(getResult.orders_usage.length !== 0);
                    setAvailablePackage(getResult.suggestions.length !== 0);
                    setOverLimit(getResult.messages_count > getResult.orders_used);
                    setShowSubStep(true);
                }
            }
        } else {
            let result;
            if (cancel) {
                result = await _campaignUpsert(token, prevCampaignValues, params.id);
            } else {
                result = await _campaignUpsert(token, campaignValues, params.id);
            }
            if (result.error) {
                console.log(`error: ${result.error}`);
                setError(result.error);
            } else {
                if (cancel) {
                    setShowSubStep(false);
                    setCampaignValues(prevCampaignValues);
                } else {
                    props.navigate('/campaigns');
                }
            }
        }
    };

    const handleInfoUpdate = (tariffName) => {
        setShowModalBuyTariff(false);
        toast.success(`Congratulations, the tariff ${tariffName} has been purchased`);
    };

    const handleValidation = (target) => {

        switch (target.name) {
            case 'campaign_name':
                // name validation
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        campaign_name: "Cannot be empty"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['campaign_name'];
                    setFormErrors(copy);
                }
                break;
            case 'message_text':
                // content validation
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        message_text: "Cannot be empty"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['message_text'];
                    setFormErrors(copy);
                }
                break;

            default: break;
        }
    };

    useEffect(() => {
        setWarning('');
        let vars = extractTemplateVars(campaignValues.message_text);
        if (vars.length) {
            setWarning('Message length depends on variables');
        }
        if ((templates.find(e => e.content == campaignValues.message_text)) == undefined)
            handleCampaignValueChange('template', { label: 'No template', value: 0 });
    }, [campaignValues.message_text]);

    useEffect(() => {
        if (campaignValues.template && campaignValues.template.value > 0) {
            handleCampaignValueChange(
                'message_text',
                campaignValues.template.content
            );
        }
    }, [campaignValues.template]);

    useEffect(() => {
        checkVars();
    }, [campaignValues.message_text]);

    const checkVars = () => {
        setWarning('');
        let vars = extractTemplateVars(campaignValues.message_text);
        if (vars.length) {
            setWarning('Message length depends on variables');
            setTemplateVars(vars);
        }
    };

    const extractTemplateVars = (template) => {
        const regex = /\{\{([0-9a-zA-Z_]*?)\}\}/g;

        let match, matches = [];
        while ((match = regex.exec(template)) !== null) {
            matches.push(match[1]);
        }
        return matches;
    };

    const dateToLocalISO = (date) => {
        let timeZoneOffset = (new Date(date)).getTimezoneOffset() * 60000;
        let localISOTime = (new Date(date - timeZoneOffset)).toISOString();
        return localISOTime;
    };

    useEffect(() => {
        let now = new Date();
        if (startDate.getTime() > now.getTime()) {
            setMinTime(now.setHours(0));
            handleCampaignValueChange('start_date', dateToLocalISO(startDate));
        } else {
            setMinTime(now);
            // if (prevStartDate.getDate() !== startDate.getDate()) {
            //     setStartDate(new Date(
            //         new Date(
            //             new Date().setHours(
            //                 new Date().getHours() + 1
            //             )
            //         ).setMinutes(0)
            //     ));
            // }
        }
    }, [startDate]);

    useEffect(() => {
        if (schedule.value === 0) {
            handleCampaignValueChange('start_date', '');
        } else {
            handleCampaignValueChange('start_date', dateToLocalISO(startDate));
        }
    }, [schedule]);

    useEffect(() => {
        if (new Date(campaignValues.start_date).getTime() > new Date().getTime()) {
            setSchedule({ label: 'Send later', value: 1 });
        }
    }, [campaignValues.start_date]);

    const formIsValid = () => {
        return !Object.keys(formErrors).length && campaignValues.campaign_name.length && campaignValues.sender;
    };

    const columns = [
        {
            dataField: 'label',
            text: 'Name',
        },
        {
            dataField: 'number',
            text: 'Number'
        }
    ];

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>Edit Campaign</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                {!showSubStep ?
                    <div className='pageContent'>
                        <div className="mb-3">
                            <label
                                className='inputLabel requiredInput'
                                htmlFor='name'>Campaign Name</label>
                            <div className='inputWrapper'>
                                <input
                                    id='campaign_name'
                                    name='campaign_name'
                                    type="text"
                                    className={`formInput ${formErrors.campaign_name ? 'invalidInput' : ''}`}
                                    placeholder="Name your campaign"
                                    value={campaignValues.campaign_name}
                                    required
                                    onChange={(event) => {
                                        handleCampaignValueChange(
                                            'campaign_name',
                                            event.target.value
                                        )
                                    }}
                                    onKeyUp={(event) => { handleValidation(event.target) }}
                                />
                                <span className='fieldError'>{formErrors.campaign_name}</span>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label
                                className='inputLabel requiredInput'
                                htmlFor='sender'>Sender</label>
                            <div className='inputWrapper'>
                                <Select
                                    id='sender'
                                    name='sender'
                                    className='customSelect'
                                    classNamePrefix='select'
                                    placeholder='Select sender'
                                    options={senders}
                                    value={campaignValues.sender}
                                    onChange={(selectedOption) => handleCampaignValueChange(
                                        'sender',
                                        selectedOption
                                    )} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label
                                className='inputLabel'
                                htmlFor='contacts'>Recipients</label>
                            <BootstrapTable
                                keyField='ID'
                                data={campaignValues.receivers}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false} />
                        </div>
                        <div className="mb-3">
                            <label
                                className='inputLabel'
                                htmlFor='template'>SMS Template</label>
                            <div className='inputWrapper'>
                                <Select
                                    id='template'
                                    name='template'
                                    className='customSelect'
                                    classNamePrefix='select'
                                    placeholder='Select template'
                                    options={templates}
                                    value={campaignValues.template}
                                    onChange={(selectedOption) => {
                                        handleCampaignValueChange(
                                            'template',
                                            selectedOption
                                        );
                                    }} />
                            </div>
                        </div>
                        <SmsCalculator
                            name='message_text'
                            value={campaignValues.message_text}
                            onChange={(event) => handleCampaignValueChange(
                                'message_text',
                                event.target.value
                            )}
                            onKeyUp={(event) => { handleValidation(event.target) }}
                            warning={warning}
                            fieldError={formErrors.message_text} />
                        <div className="mb-3">
                            <label
                                className='inputLabel'
                                htmlFor='schedule'>SMS schedule</label>
                            <Select
                                id='schedule'
                                name='schedule'
                                className='customSelect'
                                classNamePrefix='select'
                                options={schedules}
                                value={schedule}
                                onChange={(selectedOption) => setSchedule(selectedOption)} />
                        </div>
                        {schedule.value === 1 &&
                            <div className='mb-3'>
                                <div className='dateTimePickerBlock'>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {
                                            setPrevStartDate(startDate);
                                            setStartDate(new Date(date));
                                        }}
                                        minDate={new Date()}
                                        className='formInput datePicker'
                                    />
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {
                                            setPrevStartDate(startDate);
                                            setStartDate(new Date(date));
                                        }}
                                        minTime={minTime}
                                        maxTime={new Date().setHours(23, 59, 59)}
                                        className='formInput'
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="H:mm"
                                        timeFormat='H:mm'
                                    />
                                </div>
                                <div className="mb-3">
                                    <Select
                                        id='timeZones'
                                        name='timeZones'
                                        className='customSelect'
                                        classNamePrefix='select'
                                        options={timeZones}
                                        value={campaignValues.time_zone}
                                        onChange={(selectedOption) => handleCampaignValueChange(
                                            'time_zone',
                                            selectedOption
                                        )} />
                                </div>
                            </div>
                        }
                        <div className='buttonBlock'>
                            <button
                                onClick={(event) => handleUpsert(event, true)}
                                className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                                disabled={!formIsValid()}>Next</button>
                        </div>
                    </div>
                    :
                    <div className='pageContent'>
                        <div className='mb-3'>
                            <p className='wizardText'>{`Number of SMS to be sent: ${campaignInfo.messages_count}`}</p>
                            {!havePackage &&
                                <p className='wizardText'>{`Campaign cost will be ${campaignInfo.price}. Current prices will be applied.`}</p>
                            }
                            {havePackage &&
                                <div>
                                    <p className='wizardText'>{`Your Tariff Plan "${campaignInfo.tariffs[0].name}" is valid till ${new Date(campaignInfo.tariffs[0].end_date).toLocaleDateString()} and you have ${campaignInfo.orders_used}.`}</p>
                                    {!overLimit &&
                                        <p className='wizardText'>{`In case of over limit current SMS prices will be applied.`}</p>
                                    }
                                    {overLimit &&
                                        <p className='wizardText'>{`You have ${campaignInfo.messages_count - campaignInfo.orders_used}. Campaign cost will be ${campaignInfo.price}. In case of over limit current SMS prices will be applied.`}</p>
                                    }
                                </div>
                            }
                            {availablePackage &&
                                <p className='wizardText'>You can purchase Tariff Plan using following option <button className='tariffPlansBtn' onClick={() => setShowModalBuyTariff(true)}>Tariff Plan</button></p>
                            }
                        </div>
                        <div className='buttonBlock'>
                            <button
                                onClick={(event) => {
                                    handleUpsert(event, false, true);
                                }}
                                className={`mainBtn btn marginR15`}>Cancel</button>
                            <button
                                onClick={handleUpsert}
                                className={`mainBtn btn`}>Save</button>
                        </div>
                        <Modal
                            className='tariffPlansModal'
                            show={showModalBuyTariff}
                            onHide={() => setShowModalBuyTariff(false)}
                            backdrop={true}
                            keyboard={false}
                            centered>
                            {campaignInfo.suggestions.length > 0 &&
                                campaignInfo.suggestions.map((tariff) => {
                                    return (
                                        <TariffPlanTile
                                            key={tariff.ID}
                                            tariff={tariff}
                                            token={token}
                                            onBuy={handleInfoUpdate} />
                                    )
                                })
                            }
                        </Modal>
                        <Toaster />
                    </div>
                }
            </div>
        </div >
    );
};