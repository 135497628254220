import React, { useCallback, useEffect, useState } from 'react';

class gsm7bit {
    constructor() {
        this.bit7Alphabet = ["@", "£", "$", "¥", "è", "é", "ù", "ì", "ò", "Ç", "\n", "Ø", "ø", "\r", "Å", "å", "Δ", "_", "Φ", "Γ", "Λ", "Ω", "Π", "Ψ", "Σ", "Θ", "Ξ", "Æ", "æ", "ß", "É", " ", "!", '"', "#", "¤", "%", "&", "'", "(", ")", "*", "+", ",", "-", ".", "/", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ":", ";", "<", "=", ">", "?", "¡", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "Ä", "Ö", "Ñ", "Ü", "§", "¿", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "ä", "ö", "ñ", "ü", "à"];
        this.bit7Escape = ["\f", "^", "{", "}", "\\", "[", "~", "]", "|", "€"];
        this.name = "7bit";
        this.udhSize = 7;
        this.maxSize = 160;
        this.maxPartSize = this.maxSize - this.udhSize;
    }
    isEscape(char) {
        return (this.bit7Escape.indexOf(char) >= 0);
    }
    getWidth(char) {
        if (this.bit7Alphabet.indexOf(char) >= 0) {
            return 1;
        }
        if (this.isEscape(char)) {
            return 2;
        }
        return 0;
    }
};

class latin1 {
    constructor() {
        this.name = "latin1";
        this.udhSize = 6;
        this.maxSize = 140;
        this.maxPartSize = this.maxSize - this.udhSize;
    }
    isEscape(char) {
        return false;
    }
    getWidth(char) {
        const regex = /[\u0000-\u00ff]/g;
        if (char.match(regex)) {
            return 1;
        }
        return 0;
    }
};

class ucs2 {
    constructor() {
        this.name = "UCS2";
        this.udhSize = 3;
        this.maxSize = 70;
        this.maxPartSize = this.maxSize - this.udhSize;
    }
    isEscape(char) {
        return false;
    }
    getWidth(char) {
        return 1;
    }
};

const createEncoder = (name) => {
    if (name === "latin1") {
        return new latin1();
    }
    if (name === "UCS2") {
        return new ucs2();
    }
    return new gsm7bit();
};

export const SmsCalculator = (props) => {

    let timer = null;
    let default_encoder = createEncoder(props.defaultEncoder);

    const [missing, setMissing] = useState(true);
    const createCharSpan = (text, classes) => {
        let span = document.createElement("span");
        span.classList.add(...classes);
        span.append(document.createTextNode(text));
        return span;
    };

    const prepareChars = (char, encoder, base_encoder) => {
        let container = [];
        let classes = [];
        if (encoder.name !== base_encoder.name) {
            classes.push("c16b");
            if (base_encoder.getWidth(char) === 0)
                classes.push("c16bf");
        } else {
            classes.push("c7b");
            if (base_encoder.isEscape(char)) {
                container.push(createCharSpan('ESC', ["c7eb"]));
            }
        }
        if ("\n" === char) {
            container.push(createCharSpan('LF', [...classes, "c7eb"]));
        } else if ("\r" === char) {
            container.push(createCharSpan('CR', [...classes, "c7eb"]));
        } else {
            container.push(createCharSpan(char, classes));
        }
        return container;
    };

    const createUDHFld = (title, cl, value) => {
        let el = document.createElement("span");
        el.classList.add(cl);
        el.setAttribute("title", title)
        el.append(document.createTextNode(value));
        return el;
    };

    const createUDH = (smsPartsCount, partNumber) => {
        let udh = document.createElement("span");
        udh.classList.add("udh");

        udh.append(createUDHFld("UDH length", "udh-length", "0x05"));
        udh.append(createUDHFld("UDH IE - Concatenated SMS", "udh-ie-concantenated-sms", "0x00"));
        udh.append(createUDHFld("Concatenated SMS - Length", "udh-ie-length", "0x03"));
        udh.append(createUDHFld("Concatenated SMS - Reference Number", "udh-ie-concantenated-sms-ref", "0x0a"));
        udh.append(createUDHFld("Concatenated SMS - Total number of parts", "udh-ie-concantenated-sms-total", "0x" + (smsPartsCount).toString(16).padStart(2, '0')));
        udh.append(createUDHFld("Concatenated SMS - Part number in sequence", "udh-ie-concantenated-sms-part", "0x" + (partNumber).toString(16).padStart(2, '0')));

        return udh;
    };

    const createSMSPartElement = () => {
        let letterCell = document.createElement("div");
        letterCell.classList.add("sms-part");
        return letterCell;
    };

    const countCharsInMessage = (charsArray, encoder) => {
        let charsUsed = 0;
        for (let i = 0; i < charsArray.length; i++) {
            const wd = encoder.getWidth(charsArray[i]);
            if (wd === 0) {
                return -1;
            }
            charsUsed += wd;
        }
        return charsUsed;
    };


    const splitMassage = (detailsContainer, charsArray, charsUsed, encoder, base_encoder) => {
        let smsPartsCount = 1;
        let partNumber = 1;
        let charsInOneSms = encoder.maxSize;

        let smsContent = createSMSPartElement();

        if (charsUsed > encoder.maxSize) {
            charsInOneSms = encoder.maxPartSize;
            smsPartsCount = Math.ceil(charsUsed / charsInOneSms);
            let p = encoder.udhSize;
            smsContent.append(createUDH(smsPartsCount, partNumber));
            for (let i = 0; i < charsArray.length; i++) {
                const wd = encoder.getWidth(charsArray[i]);
                if (p + wd > encoder.maxSize) {
                    partNumber += 1;
                    p = encoder.udhSize;
                    detailsContainer.append(smsContent);
                    smsContent = createSMSPartElement();
                    smsContent.append(createUDH(smsPartsCount, partNumber));
                }
                smsContent.append(...prepareChars(charsArray[i], encoder, base_encoder));
                p += wd;
            }
        } else {
            for (let i = 0; i < charsArray.length; i++) {
                smsContent.append(...prepareChars(charsArray[i], encoder, base_encoder));
            }
        }
        detailsContainer.append(smsContent);

        return [smsPartsCount, charsInOneSms];
    };

    const test = (changedMessage = '') => {

        setMissing(false);
        let inputs = document.querySelectorAll("#sms-text");
        let detailsContainer = document.querySelectorAll("#sms-parts-container")[0];

        let sms_text = changedMessage.length ? changedMessage : inputs[0].value;

        clearTimeout(timer);
        timer = setTimeout(() => {
            detailsContainer.innerHTML = "";
            let charsArray = sms_text.split("");
            if (charsArray.length === 0) {
                setMissing(true);
                document.querySelector("#smslc-parts").innerHTML = 0;
                document.querySelector("#smslc-characters").innerHTML = 0;
                return;
            }
            //let base_encoder = new gsm7bit();
            //let base_encoder = new latin1();
            let base_encoder = default_encoder;
            let encoder = default_encoder;

            let charsUsed = countCharsInMessage(charsArray, base_encoder);

            if (charsUsed === -1) {
                encoder = new ucs2();
                charsUsed = charsArray.length;
            }

            const [smsPartsCount, charsInOneSms] = splitMassage(detailsContainer, charsArray, charsUsed, encoder, base_encoder);

            document.querySelector("#smslc-encoding").innerHTML = encoder.name;
            document.querySelector("#smslc-parts").innerHTML = smsPartsCount;
            document.querySelector("#smslc-characters-sms").innerHTML = charsInOneSms;
            document.querySelector("#smslc-characters").innerHTML = charsUsed;
        }, 100);
    };

    const testCallback = useCallback((msg) => { test(msg) }, []);

    useEffect(() => {
        testCallback(props.changedMessage);
    }, [testCallback, props.value, props.changedMessage]);

    return (
        <div className='sms-length-calculator mb-3'>
            <div className="messageContent">
                <label
                    className='inputLabel requiredInput'
                    htmlFor='content'>Message Content</label>
                <div className='inputWrapper'>
                    <textarea
                        name={props.name || 'content'}
                        id='sms-text'
                        className={`formInput formTextArea ${props.fieldError ? 'invalidInput' : ''}`}
                        placeholder='Type your SMS content here'
                        value={props.value}
                        onChange={(event) => {
                            test();
                            props.onChange(event);
                        }}
                        onKeyUp={props.onKeyUp}></textarea>
                    <span className='fieldError'>{props.fieldError}</span>
                </div>
                {props.warning && <p className='msgBlock warningMsg'>{props.warning}</p>}
                <div className="sendSmsBlock">
                    <div className="sms-calculator-summary">
                        <div className='smsInfoBlock'>
                            <p className='smsInfoBlockTitle'>Encoding</p>
                            <p id="smslc-encoding">{default_encoder.name}</p>
                        </div>
                        <div className='smsInfoBlock'>
                            <p className='smsInfoBlockTitle'>SMS parts</p>
                            <p id="smslc-parts">0</p>
                        </div>
                        <div className='smsInfoBlock'>
                            <p className='smsInfoBlockTitle'>Chars used</p>
                            <p id="smslc-characters">0</p>
                        </div>
                        <div className='smsInfoBlock'>
                            <p className='smsInfoBlockTitle'>Chars in SMS</p>
                            <p id="smslc-characters-sms">{default_encoder.maxSize}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='detailsBlock'>
                <label
                    className='inputLabel'>Details</label>
                <div
                    className='sendSmsBlock'
                    id='sms-parts-container'>
                </div>
                {missing &&
                    <p>Message content missing</p>
                }
            </div>
        </div>
    );
}