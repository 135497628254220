import axios from "axios";
import { HandleError } from "./errorHandler.service";

//const url = 'http://194.28.164.127:8080/api/v1';
const url = process.env.REACT_APP_API_URL;
const smsKey = process.env.REACT_APP_SMS_KEY;

export const _login = async (data) => {
    const dataToSend = {
        "email": data.email,
        "password": data.password
    };
    return await _fetch('', dataToSend, '/login');
};

export const _signUp = async (data) => {
    const dataToSend = {
        "email": data.email,
        "password": data.password,
        "last_name": data.last_name,
        "first_name": data.first_name
    };
    return await _fetch('', dataToSend, '/register');
};

export const _resetPassword = async (email) => {
    const dataToSend = {
        "email": email
    };
    return await _fetch('', dataToSend, '/reset-password-request');
};

export const _setNewPassword = async (token, password) => {
    const dataToSend = {
        "password": password
    };
    return await _fetch(`token: ${token}`, dataToSend, '/reset-password');
};

export const _profileGet = async (token) => {
    return await _get(`token: ${token}`, `/profile`);
};

export const _profileSave = async (token, data) => {
    const dataToSend = {
        "first_name": data.first_name,
        "last_name": data.last_name,
    }
    if (data.password) dataToSend['password'] = data.password;
    return await _fetch(`token: ${token}`, dataToSend, `/profile`);
};

export const _balanceTopUp = async (token, amount) => {
    return _fetch("token: " + token, { "amount": amount }, `/top-up`);
};

export const _tariffPlansGet = async (token) => {
    return await _get(`token: ${token}`, `/tariff-packages`);
};

export const _ordersGet = async (token) => {
    return await _get(`token: ${token}`, `/orders`);
};

export const _buyTariff = async (token, id) => {
    return _fetch("token: " + token, { "package_id": id }, `/orders`);
};

export const _campaignsGet = async (token, search) => {
    return _get(`token: ${token}`, `/campaigns${search ? `?search=${search}` : ''}`);
};

export const _campaignGetById = async (token, id) => {
    return _get(`token: ${token}`, `/campaigns/${id}`);
};

export const _campaignUpsert = async (token, data, id = '', draftEdit = false) => {
    let receiversArr = [];
    if (data.numbers && data.numbers.length) {
        receiversArr = data.numbers;
    } else {
        receiversArr = data.receivers;
    }
    const receivers = receiversArr.map((receiver) => {
        return {
            name: receiver.label,
            number: receiver.number,
            fields: receiver.fields
        }
    });
    const dataToSend = {
        "name": data.campaign_name,
        "sender": data.sender.label,
        "text": data.message_text,
        "recipients": receivers
    }
    if (data.template) dataToSend['template_id'] = data.template.value;
    if (data.start_date) {
        dataToSend['start_at'] = data.start_date;
        dataToSend['time_zone'] = data.time_zone.label;
    }
    console.log("data", dataToSend);
    if (id) {
        if (!draftEdit) dataToSend['status'] = 1;
        return await _fetch(`token: ${token}`, dataToSend, `/campaigns/${id}`, true);
    }
    return await _fetch(`token: ${token}`, dataToSend, `/campaigns`);
};

export const _checkCampaignNameIsUnique = async (token, name) => {
    let route = `/campaigns/check-name?name=${name}`;
    try {
        const response = await fetch(url + route, {
            method: 'GET',
            headers: {
                'Authorization': `token: ${token}`,
                'X-MC-SMS-Key': smsKey,
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            const json = await response.json();
            return json;
        } else {
            return true;
        }
    } catch (error) { }
};

export const _campaignSMSReport = async (token, search) => {
    return await _get(`token: ${token}`, `/reports/campaigns/messages${search ? `?${search}` : ''}`);
};

export const _getTimeZones = async (token) => {
    return await _get(`token: ${token}`, `/time-zones`);
};

export const _campaignDelete = async (token, id) => {
    return await _delete(`token: ${token}`, `/campaigns/${id}`);
};

export const _sendersGet = async (token) => {
    return await _get("token: " + token, `/senders`);
};

export const _templatesGet = async (token, search) => {
    return await _get("Bearer " + token, `/sms-templates${search ? `?search=${search}` : ''}`);
};

export const _templateGetById = async (token, id) => {
    return await _get("Bearer " + token, `/sms-templates/${id}`);
};

export const _templateCreate = async (token, data) => {
    const dataToSend = {
        "name": data.name,
        "content": data.content
    };
    return await _fetch("token: " + token, dataToSend, `/sms-templates`);
};

export const _templateEdit = async (token, data, id) => {
    const dataToSend = {
        "name": data.name,
        "content": data.content
    };
    return await _fetch("token: " + token, dataToSend, `/sms-templates/${id}`, true);
};

export const _templateDelete = async (token, id) => {
    return await _delete("token: " + token, `/sms-templates/${id}`);
};

export const _contactsGet = async (token, search) => {
    return await _get("token: " + token, `/contacts${search ? `?search=${search}` : ''}`);
};

export const _contactGetById = async (token, id) => {
    return await _get("token: " + token, `/contacts/${id}`);
};

export const _contactCreate = async (token, data) => {
    const dataToSend = {
        "name": data.name,
        "number": data.number,
        "fields": data.fields
    };
    return await _fetch("token: " + token, dataToSend, `/contacts`);
};

export const _contactEdit = async (token, data, id) => {
    const dataToSend = {
        "name": data.name,
        "number": data.number,
        "fields": data.fields
    };
    return await _fetch("token: " + token, dataToSend, `/contacts/${id}`, true);
};

export const _contactDelete = async (token, id) => {
    return await _delete("token: " + token, `/contacts/${id}`);
};

export const _pricesGet = async (token, search) => {
    return await _get("token: " + token, `/prices${search ? `?search=${search}` : ''}`);
};

export const _sendSMS = async (token, data) => {
    const dataToSend = {
        "text": data.changed_message || data.message_text,
        "recipient": data.receiver.number,
        "sender": data.sender.label
    }
    return await _fetch("token: " + token, dataToSend, `/send-message`);
};

export const _individualSMSReport = async (token, search) => {
    return await _get(`token: ${token}`, `/reports/messages/sent-through-ui${search ? `?${search}` : ''}`);
};

const axiosInstance = axios.create({
    baseURL: url,
    headers: {
        'X-MC-SMS-Key': smsKey,
        'Content-Type': 'application/json',
    }
});

const _get = async (token, route) => {
    axiosInstance.defaults.headers.common['Authorization'] = token;
    try {
        const response = await axiosInstance.get(route);
        return response.data;
    } catch (error) {
        HandleError(error);
    }
};

const _fetch = async (token = '', data = {}, route = '', put = false) => {
    axiosInstance.defaults.headers.common['Authorization'] = token;
    try {
        let response;
        if (put) response = await axiosInstance.put(route, data);
        else response = await axiosInstance.post(route, data);
        return response.data;
    } catch (error) {
        HandleError(error);
    }
};

const _delete = async (token, route) => {
    axiosInstance.defaults.headers.common['Authorization'] = token;
    try {
        await axiosInstance.delete(route);
    } catch (error) {
        HandleError(error);
    }
};
