import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import { FormData } from '../../helpers/FormData';

import { _signUp } from '../../services/apiConnections.service';
import { TermsAndConditions } from '../elements/TermsAndConditions.component';
import { PrivacyPolicy } from '../elements/PrivacyPolicy.component';

export const SignUp = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        email: '',
        password: '',
        confirm_password: '',
        first_name: '',
        last_name: '',
        TaC: false,
        Privacy: false
    });
    const [showModalTaC, setShowModalTaC] = useState(false);
    const [showModalPrivacy, setShowModalPrivacy] = useState(false);
    const [error, setError] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formValues.TaC && !formValues.Privacy) {
            setFormErrors(formErrors => ({
                ...formErrors,
                TaC: "You must agree with Terms and Conditions",
                Privacy: "You must agree with Privacy Policy"
            }));
            return;
        } else if (!formValues.TaC) {
            setFormErrors(formErrors => ({
                ...formErrors,
                TaC: "You must agree with Terms and Conditions"
            }));
            return;
        } else if (!formValues.Privacy) {
            setFormErrors(formErrors => ({
                ...formErrors,
                Privacy: "You must agree with Privacy Policy"
            }));
            return;
        }
        let result = await _signUp(formValues);
        if (result.error) {
            setError(result.error);
        } else {
            console.log("redirect to log-in");
            props.navigate('/log-in');
        }
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'email':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        email: "Email is required"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['email'];
                    setFormErrors(copy);
                }
                break;
            case 'first_name':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        first_name: "First Name is required"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['first_name'];
                    setFormErrors(copy);
                }
                break;
            case 'last_name':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        last_name: "Last Name is required"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['last_name'];
                    setFormErrors(copy);
                }
                break;
            case 'password':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        password: "Password is required"
                    }));
                    console.log(target.value.length)
                } else if (target.value && target.value != formValues.confirm_password) {
                    let copy = { ...formErrors };
                    delete copy['password'];
                    setFormErrors(copy);
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        confirm_password: "Must match your Password"
                    }));
                } else if (target.value && target.value == formValues.confirm_password) {
                    let copy = { ...formErrors };
                    delete copy['confirm_password'];
                    delete copy['password'];
                    setFormErrors(copy);
                }
                else {
                    let copy = { ...formErrors };
                    delete copy['password'];
                    setFormErrors(copy);
                }
                break;
            case 'confirm_password':
                if (target.value != formValues.password) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        confirm_password: "Must match your Password"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['confirm_password'];
                    setFormErrors(copy);
                }
                break;
            default: break;
        }
    };

    useEffect(() => {
        if (formValues.TaC) {
            let copy = { ...formErrors };
            delete copy['TaC'];
            setFormErrors(copy);
        }
    }, [formValues.TaC]);

    useEffect(() => {
        if (formValues.Privacy) {
            let copy = { ...formErrors };
            delete copy['Privacy'];
            setFormErrors(copy);
        }
    }, [formValues.Privacy]);

    const formIsValid = () => {
        return !Object.keys(formErrors).length && formValues.email && formValues.password && formValues.confirm_password && formValues.first_name && formValues.last_name;
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='loginPageLogo'>
            </div>
            {error && <p className='msgBlock errorMsg'>{error}</p>}
            <div className="mb-3">
                <label
                    className='loginLabel'
                    htmlFor='email'>Email</label>
                <div className='inputWrapper'>
                    <input
                        id='email'
                        name='email'
                        type="email"
                        className={`loginInput ${formErrors.email ? 'invalidInput' : ''}`}
                        placeholder="Email"
                        required
                        value={formValues.email}
                        onChange={(event) => handleFormValueChange(
                            'email',
                            event.target.value
                        )}
                        onKeyUp={(event) => { handleValidation(event.target) }}
                    />
                    <span className='fieldError'>{formErrors.email}</span>
                </div>
            </div>
            <div className="mb-3">
                <label
                    className='loginLabel'
                    htmlFor='password'>Password</label>
                <div className='inputWrapper'>
                    <input
                        id='password'
                        name='password'
                        type="password"
                        className={`loginInput ${formErrors.password ? 'invalidInput' : ''}`}
                        placeholder="Password"
                        required
                        value={formValues.password}
                        onChange={(event) => handleFormValueChange(
                            'password',
                            event.target.value
                        )}
                        onKeyUp={(event) => { handleValidation(event.target) }}
                    />
                    <span className='fieldError'>{formErrors.password}</span>
                </div>
            </div>
            <div className="mb-3">
                <label
                    className='loginLabel'
                    htmlFor='confirm_password'>Confirm Password</label>
                <div className='inputWrapper'>
                    <input
                        id='confirm_password'
                        name='confirm_password'
                        type="password"
                        value={formValues.confirm_password}
                        className={`loginInput ${formErrors.confirm_password ? 'invalidInput' : ''}`}
                        placeholder="Confirm Password"
                        onChange={(event) => handleFormValueChange(
                            'confirm_password',
                            event.target.value
                        )}
                        onKeyUp={(event) => { handleValidation(event.target) }}
                    />
                    <span className='fieldError'>{formErrors.confirm_password}</span>
                </div>
            </div>
            <div className="mb-3">
                <label
                    className='loginLabel'
                    htmlFor='first_name'>First Name</label>
                <div className='inputWrapper'>
                    <input
                        id='first_name'
                        name='first_name'
                        type="text"
                        value={formValues.first_name}
                        className={`loginInput ${formErrors.first_name ? 'invalidInput' : ''}`}
                        placeholder="First Name"
                        required
                        onChange={(event) => handleFormValueChange(
                            'first_name',
                            event.target.value
                        )}
                        onKeyUp={(event) => { handleValidation(event.target) }}
                    />
                    <span className='fieldError'>{formErrors.first_name}</span>
                </div>
            </div>
            <div className="mb-3">
                <label
                    className='loginLabel'
                    htmlFor='last_name'>Last Name</label>
                <div className='inputWrapper'>
                    <input
                        id='last_name'
                        name='last_name'
                        type="text"
                        value={formValues.last_name}
                        className={`loginInput ${formErrors.last_name ? 'invalidInput' : ''}`}
                        placeholder="Last Name"
                        required
                        onChange={(event) => handleFormValueChange(
                            'last_name',
                            event.target.value
                        )}
                        onKeyUp={(event) => { handleValidation(event.target) }}
                    />
                    <span className='fieldError'>{formErrors.last_name}</span>
                </div>
            </div>
            <div className="mb-3 inputWrapper">
                <label htmlFor='TaC' className='loginLinkText'>
                    <input
                        id='TaC'
                        type="checkbox"
                        name="TaC"
                        checked={formValues.TaC}
                        onChange={() => handleFormValueChange(
                            'TaC',
                            !formValues.TaC
                        )}
                    />
                    {" "}I agree with{" "}
                    <button
                        className="loginLink"
                        onClick={() => {
                            setShowModalTaC(true)
                        }}>Terms and Conditions</button>
                </label>
                <span className='fieldError'>{formErrors.TaC}</span>
            </div>
            <div className="mb-3 inputWrapper">
                <label htmlFor='Privacy' className='loginLinkText'>
                    <input
                        id='Privacy'
                        type="checkbox"
                        name="Privacy"
                        checked={formValues.Privacy}
                        onChange={() => handleFormValueChange(
                            'Privacy',
                            !formValues.Privacy
                        )}
                    />
                    {" "}I agree with{" "}
                    <button
                        className="loginLink"
                        onClick={() => {
                            setShowModalPrivacy(true)
                        }}>Privacy Policy</button>
                </label>
                <span className='fieldError'>{formErrors.Privacy}</span>
            </div>
            <div className="btnWrapper">
                <button
                    type="submit"
                    className="btn loginBtn mainBtn"
                    disabled={!formIsValid()}>Sign Up</button>
            </div>
            <p className="loginLinkText">
                Already registered? <Link className='loginLink' to={'/log-in'}>Log In</Link>
            </p>
            <Modal
                className='signupPageModal'
                show={showModalTaC}
                onHide={() => setShowModalTaC(false)}
                backdrop="static"
                keyboard={false}
                centered>
                <Modal.Header closeButton>
                    <div className='pageTitle'>
                        <span>General Terms &amp; Conditions</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <TermsAndConditions />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className='btn mainBtn acceptBtn'
                        onClick={() => setShowModalTaC(false)}>Close</button>
                </Modal.Footer>
            </Modal>
            <Modal
                className='signupPageModal'
                show={showModalPrivacy}
                onHide={() => setShowModalPrivacy(false)}
                backdrop="static"
                keyboard={false}
                centered>
                <Modal.Header closeButton>
                    <div className='pageTitle'>
                        <span>Privacy Policy</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <PrivacyPolicy />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className='btn mainBtn acceptBtn'
                        onClick={() => setShowModalPrivacy(false)}>Close</button>
                </Modal.Footer>
            </Modal>
        </form>
    );
}