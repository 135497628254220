import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { _contactDelete, _contactsGet } from '../../services/apiConnections.service';
import { ModalDialog } from '../elements/ModalDialog.component';

export const Contacts = () => {

    const [contacts, setContacts] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [contactToDelete, setContactToDelete] = useState({});

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (token && !contacts.length && !emptyTable) {
            getData(token);
        }
    }, [token, contacts, emptyTable]);

    const getData = async (token, search = '') => {
        let result = await _contactsGet(token, search);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setEmptyTable(!result.Contacts.length);
            setContacts(result.Contacts);
        }
        setError(result.error);
    };

    const handleFilter = (value) => {
        getData(token, value);
    };

    const editFormatter = (cell, row) => {
        return (
            <Link className='tableIconBtn' to={`/contacts/${cell}`}>
                <FontAwesomeIcon icon={solid('pencil')} />
            </Link>
        )
    };

    const deleteFormatter = (cell, row) => {
        return (
            <button
                className='tableIconBtn trashBtn'
                onClick={() => {
                    setContactToDelete(row);
                    setShowDeleteDialog(true);
                }}>
                <FontAwesomeIcon icon={regular('trash-can')} />
            </button>
        )
    };

    const handleDeleteContact = async (id) => {
        await _contactDelete(token, id);
        getData(token);
        setShowDeleteDialog(false);
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true
        },
        {
            dataField: 'number',
            text: 'Phone',
            sort: true
        },
        {
            dataField: 'ID',
            text: 'Edit',
            classes: 'iconColumn',
            formatter: editFormatter
        },
        {
            dataField: 'user_id',
            text: 'Delete',
            classes: 'iconColumn',
            formatter: deleteFormatter
        }
    ];

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'asc'
        }
    ];

    const noData = () => {
        return <p className='emptyTableMessage'>You have no contacts yet! Create it</p>
    };

    return (
        <div className='content wideContent'>
            <div>
                <div className='pageTitle'>
                    <span>Contacts</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader tableHeaderInline'>
                                <div className='filterCol'>
                                    <label
                                        className='inputLabel'
                                        htmlFor='nameFilter'>Name</label>
                                    <input
                                        name='nameFilter'
                                        className='searchInput'
                                        placeholder='Search...'
                                        onChange={(event) => handleFilter(event.target.value)} />
                                </div>
                                <Link className='mainBtn btn' to={'/create-contact'}>Create Contact</Link>
                            </div>
                            <BootstrapTable
                                keyField='ID'
                                data={contacts}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                pagination={paginationFactory()}
                                defaultSorted={defaultSorted}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
                <ModalDialog
                    show={showDeleteDialog}
                    onHide={() => setShowDeleteDialog(false)}
                    onAccept={async () => handleDeleteContact(contactToDelete.ID)}
                    title={`Delete contact ${contactToDelete.name}`}
                    acceptBtnText="Delete">
                    <span>{`Are you sure you want to delete contact '${contactToDelete.name}'?`}</span>
                </ModalDialog>
            </div>
        </div>
    );
}